import "babel-polyfill"
import React from 'react'
import ReactDOM from 'react-dom'
import App from 'formular/App'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'


firebase.initializeApp({
  apiKey: "AIzaSyAyRZIGAYBAOICmgS9IIAmKOpb9DPWydXo",
  authDomain: "messetool-pmta.firebaseapp.com",
  databaseURL: "https://messetool-pmta.firebaseio.com",
  projectId: "messetool-pmta",
  storageBucket: "messetool-pmta.appspot.com",
  messagingSenderId: "179962393703",
  appId: "1:179962393703:web:3258086aaad0a0f06410c2"
})

const pathname = window.location.pathname.split('/')[1]
let organizerId = ''
let expoId = ''

if(pathname === '74BI83'){
  organizerId = 'demoOrganizer'
  expoId = '04-10-19-Frankfurt'
}

if(pathname === '6Z62GH'){
  organizerId = 'ibs'
  expoId = '2020'
}

if(pathname === '4ZB3M8'){
  organizerId = 'ibs'
  expoId = '2021'
}

if(pathname === 'K83KD3'){
  organizerId = 'ibs'
  expoId = '2022'
}

if(pathname === '78HU6G'){
  organizerId = 'ibs'
  expoId = '2023'
}

if(pathname === 'A84UTK'){
  organizerId = 'ibs'
  expoId = '2024'
}

if(pathname === '54RT78'){
  organizerId = 'BAM'
  expoId = '2020'
}

if(pathname === '98G5UN'){
  organizerId = 'BAM'
  expoId = '2021'
}

if(pathname === '47KD29'){
  organizerId = 'BAM'
  expoId = '2022'
}

if(pathname === 'G648ED'){
  organizerId = 'BAM'
  expoId = '2023'
}

if(pathname === 'HUVP24'){
  organizerId = 'BAM'
  expoId = '2024'
}

if(pathname === '8UKL9Z'){
  organizerId = 'ulmerHochzeitstag'
  expoId = '2024'
}

if(pathname === '8UKL92'){
  organizerId = 'UlmerHochzeitstagMesse'
  expoId = '2024'
}

if(pathname === '111111'){
  organizerId = 'DEMO'
  expoId = '2021'
}

if(pathname === '111112'){
  organizerId = 'DEMO'
  expoId = '2022'
}

ReactDOM.render(<App {...{organizerId, expoId}}/>, document.getElementById('root'))

