// @flow
import * as React from 'react'

export default {
  material: React.lazy(() => import(`./material/Layout`)),
  curved: React.lazy(() => import(`./curved/Layout`))
}

export const widgets = {
  material: {
    Button: React.lazy(() => import(`./material/widgets/Button`)),
    CheckboxWithTextModal: React.lazy(() =>
      import(`./material/widgets/CheckboxWithTextModal`)
    ),
    CheckboxWithTextWithLink: React.lazy(() =>
      import(`./material/widgets/CheckboxWithTextWithLink`)
    ),
    Headline: React.lazy(() => import(`./material/widgets/Headline`)),
    MultipleCloneableRows: React.lazy(() =>
      import(`./material/widgets/MultipleCloneableRows`)
    ),
    Paragraph: React.lazy(() => import(`./material/widgets/Paragraph`)),
    Select: React.lazy(() => import(`./material/widgets/Select`)),
    String: React.lazy(() => import(`./material/widgets/String`)),
    Number: React.lazy(() => import(`./material/widgets/Number`)),
    StringWithSuggestions: React.lazy(() =>
      import(`./material/widgets/StringWithSuggestions`)
    ),
    Textarea: React.lazy(() => import(`./material/widgets/Textarea`)),
    TextWithLink: React.lazy(() => import(`./material/widgets/TextWithLink`))
  },
  curved: {
    Button: React.lazy(() => import(`./curved/widgets/Button`)),
    Headline: React.lazy(() => import(`./curved/widgets/Headline`)),
    Select: React.lazy(() => import(`./curved/widgets/Select`)),
    String: React.lazy(() => import(`./curved/widgets/String`))
  }
}
