// @flow
import * as React from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

export default function NotFound() {
  
  const classes = useStyles()

  return (
    <Typography component='div' className={classes.notFound}>
      <h1>Wir konnten das Projekt nicht finden</h1>
    </Typography>
  )
}


const useStyles = makeStyles(theme => ({
  notFound: {
    margin: '200 auto',
    '> h1': {
      textAlign: 'center',
      fontWeight: '100'
    }
  }
}))