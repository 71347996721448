// @flow
import * as React from 'react'

export default function SuccessPage () {
  return (
    <div>
      <h2>Vielen Dank für Ihre Anmeldeanfrage</h2>
      <p>In Kürze erhalten Sie eine eMail zur Bestätigung Ihrer Anmeldeanfrage.</p>
      <p>Ihr Messeteam</p>
    </div>
  )
}