// @flow
import * as React from 'react'
import firebase from 'firebase/app'

export default function useFirestore(path: string, refine = ref => ref) {
  const [value, setValue] = React.useState(null)
  const [valueDict, setValueDict] = React.useState(null)
  const [isFetching, setIsFetching] = React.useState(true)
  const docRef = React.useRef(null)
  const staticRefine = React.useRef(refine)

  React.useEffect(() => {
    if (!path) return
    const isDoc = path.split('/').length % 2 === 0
    const db = firebase.firestore()
    let ref
    if (isDoc) ref = db.doc(path)
    else ref = db.collection(path)
    ref = staticRefine.current(ref)
    docRef.current = ref

    return ref.onSnapshot(doc => {
      setIsFetching(false)
      if (isDoc && doc && doc.exists) {
        setValue(doc.data())
      }
      if (!isDoc) {
        setValue(doc.docs.map(doc => doc.data()))
        const dict = {}
        doc.docs.map(doc => {
          dict[doc.id] = doc.data()
        })
        setValueDict(dict)
      }
    })
  }, [path])

  return [value, isFetching, docRef.current, valueDict]
}
