// @flow
import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

export default function NotFound () {
  
  const classes = useStyles()

  return (
    <div className={classes.appLoading}>
      <CircularProgress disableShrink />
    </div>
  )
}



const useStyles = makeStyles(theme => ({
  appLoading: {
    height: '100vh',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'    
    }
  }))