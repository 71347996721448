// @flow
import * as React from 'react'
import { VariablesContext } from 'formular/hooks/useVariables'
import { widgets } from './themes'
import firebase from 'firebase/app'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import SuccessPage from './SuccessPage'
import validate from './utils/validate'

export default function Formular({
  formularConfig,
  initialValues = {},
  organizerId,
  expoId,
  uid
}) {
  const [variables, _setVariables] = React.useState(initialValues.__variables || formularConfig.variables)
  const [userInput, setUserInput] = React.useState(initialValues)
  const [errors, setErrors] = React.useState({})
  const [success, setSuccess] = React.useState(false)

  function updateUserInput (id, value) {
    setUserInput({...userInput, [id]:value})
    if(Object.keys(errors).length){
      setErrors({})
    }
  }

  const send = React.useCallback(() => {
    const uploadData = createUploadData(userInput, variables, formularConfig)
    if (uid) {
      setSuccess(true)
      firebase
        .firestore()
        .doc(`organizers/${organizerId}/expos/${expoId}/exhibitors/${uid}`)
        .update(uploadData)
    }
    else {
      setSuccess(true)
      firebase
        .firestore()
        .collection(`organizers/${organizerId}/expos/${expoId}/exhibitors`)
        .add(uploadData)
    }
  }, [organizerId, expoId, uid, userInput, formularConfig, variables])

  const setVariable = React.useCallback(
    (key, value) => {
      if (key === 'SEND_FORMULAR') {
        const validation = validate(formularConfig, userInput)
        if (validation.status === 'OK') send()
        else setErrors(validation.errors)
      } else _setVariables(Object.assign({}, variables, { [key]: value }))
    },
    [variables, send, formularConfig, userInput]
  )

  const context = React.useMemo(() => {
    return [variables, setVariable]
  }, [variables, setVariable])

  if(success) return <SuccessPage/>

  return (
    <VariablesContext.Provider value={context}>
      <div className='Formula'>
        {formularConfig.fields.map(field => (
          <Field
            key={field._id}
            field={field}
            fields={formularConfig.fields}
            userInput={userInput}
            initialValue={initialValues[field._id]}
            error={errors[field._id] || false}
            onUpdate={val => updateUserInput(field._id, val)}
          />
        ))}
      </div>
    </VariablesContext.Provider>
  )
}

function Field({ field, initialValue, onUpdate, error, fields, userInput }) {
  const [value, setValue] = React.useState(typeof(initialValue) !== 'undefined' ? initialValue : field.defaultValue)
  const [variables] = React.useContext(VariablesContext)
  const [focus, setFocus] = React.useState(false)
  const tempValue = React.useRef(value)

  React.useEffect(() => {
    if(value === tempValue.current) return
    tempValue.current = value
    onUpdate(value)
  }, [onUpdate, value])

  const useStyles = makeStyles(theme => ({
    field: {
      '&:focus': {
        outline: 'none'
      }
    }
  }))
  const classes = useStyles()

  if (field.appearance) {
    const { key, value } = field.appearance
    if(key[0] === '$' && userInput[key.slice(1)] !== value) return null
    if (key[0] !== '$' && value !== variables[key]) return null
  }

  const Component = widgets[field.theme][field.widget]

  return (
    <Typography
      component='div'
      className={classes.field}
      tabIndex='0'
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
    >
      <Component
        {...field.props}
        value={value}
        onChange={setValue}
        active={focus}
        error={error}
        isRequired={Boolean(field.validation)}
      />
    </Typography>
  )
}

function createUploadData (userInput, variables, formularConfig) {
  let uploadData = Object.assign({}, userInput)
  formularConfig.fields.forEach(field => {
    // remove not visible fields
    if(field.appearance){
      if(field.appearance.key[0] === '$'){
        if(uploadData[field.appearance.key.slice(1)] !== field.appearance.value){
          delete uploadData[field._id]
        }
      }
      else {
        if(variables[field.appearance.key] !== field.appearance.value){
          delete uploadData[field._id]
        }
      }
    }
    // // add not edited fields
    if(!uploadData[field._id] && typeof field.untouchedValue !== 'undefined'){
      uploadData[field._id] = field.untouchedValue
    }

    // add varialbes
    uploadData.__variables = variables
  })
  return uploadData
}