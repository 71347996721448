import * as React from 'react'
import Formular from './Formular'
import NotFound from './NotFound'
import Loading from './Loading'
import themes from './themes'
import useFirestore from 'hooks/useFirestore'


export default function App({organizerId, expoId, initialValues, uid}) {
  const [formular,isFetching] = useFirestore(`organizers/${organizerId}/expos/${expoId}/formulars/published`)

  if(isFetching) return <Loading/>

  if(!formular || !formular.themeConfig || !formular.formularConfig) return <NotFound />

  const Layout = themes[formular.formularConfig.globals.theme]

  return (
    <div className='Formular' style={{width:'100%'}}>
      <React.Suspense fallback={<Loading/>}>
        <Layout themeConfig={formular.themeConfig}>
          <Formular 
            formularConfig={formular.formularConfig}
            initialValues={initialValues}
            organizerId={organizerId}
            expoId={expoId}
            uid={uid}
          />
        </Layout>
      </React.Suspense>
    </div>
  )
}
