// @flow
import scrollToElement from 'scroll-to-element'

export default function validate(formularConfig, inputs) {
  let result = { status: 'OK', errors: {} }

  function doValidate (field, input, fieldId, onError) {
    if (!field.validation && field.widget !== 'MultipleCloneableRows') return
    input = input || inputs[field._id]
    fieldId = fieldId || field._id

    if (field.widget === 'MultipleCloneableRows') {
      result.errors[fieldId] = []
      if(!input) {
        input = [field.props.rows.reduce((p,n) => Object.assign(p, {[n._id]:n.defaultValue}), {})]
      }
      input.forEach((row,i) => {
        const innerErrors = field.props.rows.reduce((prev,next) => Object.assign(prev, {[next._id]:false}), {})
        field.props.rows.forEach(innerField => {
          doValidate(innerField, row[innerField._id], field._id, () => {
            result.status = 'ERROR'
            innerErrors[innerField._id] = innerField.validation.message
          })
        })
        result.errors[fieldId].push(innerErrors)
      })
    }

    else if (field.validation.logic === 'not-empty') {
      if (!input || input === false) {
        onError()
      }
    }

    else if (field.validation.logic === 'function') {
      let error = false
      const func = eval(field.validation.function) // eslint-disable-line
      try {
        error = func(!!input)
      } catch (e) {}

      if (error) {
        onError()
      }
    }

    else if (field.validation.logic === 'regex') {
      const regex = new RegExp(field.validation.regex)
      const inpt = !input ? '' : input
      if (!regex.test(inpt.toString())) {
        onError()
      }
    }
  }

  formularConfig.fields.forEach(field => doValidate(field, null, null, () => {
    result.status = 'ERROR'
    result.errors[field._id] = field.validation.message
  }))

  if (result.status === 'ERROR') {
    setTimeout(() => {
      scrollToElement('.Mui-error', {
        offset: -30,
        ease: 'out-bounce',
        duration: 150
      })
    }, 1)
  }



  return result
}